import React from 'react';
import LudoMain from './LudoMain';
import { LudoProvider } from './assets/initialState';

const Ludo = () => {
return (
<>
<LudoProvider>
<LudoMain />
</LudoProvider>
</>
)
}

export default Ludo;
