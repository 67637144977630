import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import SideMenu from '../extra/SideMenu';
import Footer from '../extra/Footer';
import AcceptAge from '../modals/AcceptAge';
import { GlobalContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import FrontPay from './FrontPay';

const VirtualMain = () => {

const { thGameImg, customer, spoSett } = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const [pageVirImg, setPageVirImg] = useState([]);
const Navigate = useNavigate();

const headTail = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/head-tails');   
}
}
else{
Navigate('/head-tails');
}
}
else{
$('#AcceptAgeModal').show();
}        
}

const lucky0to9 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/lucky09');   
}
}
else{
Navigate('/lucky09');
}
}
else{
$('#AcceptAgeModal').show();
}       
}

const dice1 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/dice1');   
}
}
else{
Navigate('/dice1');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const ludoking = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/ludoking');   
}
}
else{
Navigate('/ludoking');
}
}
else{
$('#AcceptAgeModal').show();
}  
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lock2').css({display: 'flex'});
}else{
$('#sports_lock2').hide();
}
}
},[lockSport]);

useEffect(() => {
if(thGameImg.length > 0){
setPageVirImg(thGameImg.filter((e) => e.sport === 'virtual'));
}
},[thGameImg]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='singleImage'>
{
pageVirImg.length > 0 ?
<img src={pageVirImg[0].path} alt='singleImg' />
:
<img src='/images/slide5.png' alt='singleImg' />
}
</div>

<FrontPay />

<div className='game-contents'>
<div className='multimarketdIV'><img src='/images/virtualCasino.png' alt='highImg' /> <span>all available virtual casino</span></div>
<div className='rowsFours'>

<div className='sports_lock' id='sports_lock2'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/head.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Head & Tails</span></div>
<div className='colsSm' onClick={() => headTail()}><span>Play Now</span></div>
</div>
</div>

<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/luc9.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Lucky 0 To 9</span></div>
<div className='colsSm' onClick={() => lucky0to9()}><span>Play Now</span></div>
</div>
</div>

<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/dices.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Roll Dice</span></div>
<div className='colsSm' onClick={() => dice1()}><span>Play Now</span></div>
</div>
</div>

<div className='colsFourSm' style={{display: 'none'}}>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/ludo.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>ludo king</span></div>
<div className='colsSm' onClick={() => ludoking()}><span>Play Now</span></div>
</div>
</div>

</div>

<Footer />
</div>
</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default VirtualMain;
