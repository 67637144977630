import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const onlBankDataApi = '/server/bank_dataSelf';

const EditBank = ({editBankData, setEditBankData}) => {

const { GetBankData } = GlobalContext();

const closedUpdBank = () => {
$('#userBankUpdModal').hide();
setEditBankData([]);
}

const updateUserBank = async(type) => {
var id = editBankData.length > 0 ? editBankData[0].id : null;
var bankName = $('#updUsrBname').val();
var holderName = $('#updUsrBHN').val();
var bankAcNo = $('#updUsrBAC').val();
var ifsc = $('#updUsrBIFSC').val();

if(bankName === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('bank name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(holderName === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('bank holder name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(bankAcNo === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('bank account no. required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(ifsc === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('bank ifsc code required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/edit_bank', {
type: type,
id: id,
bankName: bankName,
holderName: holderName,
bankAcNo: bankAcNo,
ifsc: ifsc
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('bank updated successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#userBankUpdModal').hide(); 
GetBankData(onlBankDataApi);
setEditBankData([]); 
},1000);
return false; 
}
else if(resData === 'success2'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('bank removed successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#userBankUpdModal').hide(); 
GetBankData(onlBankDataApi);
setEditBankData([]); 
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);   
}
} catch (error) {
console.log('frontend edit_remove_bank error : ' + error);
}
}
}

return (
<>
<div className='signUpModal' id='userBankUpdModal'>
<div className='signUpCon'>
<div className='signUpHead'>
<span>update/remove bank</span>
<i className='fa fa-close' onClick={closedUpdBank} />
</div>

<div className='signUpBody'>
{
editBankData.length > 0 &&
<div className='signupForm' id='signupForm'>
<div className='signUpData'>
<label>bank name *</label>
<input type='text' defaultValue={editBankData[0].bankName} name='updUsrBname' id='updUsrBname' placeholder='Enter bank name...' />
</div>

<div className='signUpData'>
<label>bank holder name *</label>
<input type='text' defaultValue={editBankData[0].holderName} name='updUsrBHN' id='updUsrBHN' placeholder='Enter bank holder name...' />
</div>

<div className='signUpData'>
<label>bank account no. *</label>
<input type='text' defaultValue={editBankData[0].bankAcNo} name='updUsrBAC' id='updUsrBAC' placeholder='Enter account number...' />
</div>

<div className='signUpData'>
<label>IFSC code *</label>
<input type='text' defaultValue={editBankData[0].bankIfsc} name='updUsrBIFSC' id='updUsrBIFSC' placeholder='Enter bank ifsc code...' />
</div>

<div className='signUpConf signUpBtnD'>
<button onClick={() => updateUserBank('update')}>update bank</button>
<button onClick={() => updateUserBank('reject')}>remove bank</button>
</div>

</div>
}
</div>

</div>
</div>
</>
)
}

export default EditBank;
