import React, { useCallback, useMemo } from 'react';
import { backGgroundImage } from './assets/images';
import { GlobalLudo } from './assets/initialState';

const Pile = ({color, player, peaceNo, data, onClick}) => {
return (
<>
<div className='pileBoxLudo'>
<div className='pilDivLudo'>
<div className='pileLudo' style={{backgroundColor: color}}>
{
data && data[peaceNo].pos === 0 &&
<PilePlot color={color} peaceNo={peaceNo} player={player} onClick={() => onClick(data[peaceNo])} />
}
</div>
</div>
</div>
</>
)
}

const PilePlot = ({color, peaceNo, cell, player, onClick}) => {

const { ludostate } = GlobalLudo();

const currPileSelection = ludostate.selectionPilePlayer;
const currCellSelection = ludostate.selectionCellPlayer;
const diceNo = ludostate.diceNo;
const playerPiles = ludostate[`player${player}`];

const isPileEnabled = useMemo(() =>
player === currPileSelection,
[player,currPileSelection]);

const isCellEnabled = useMemo(() =>
player === currCellSelection,
[player,currCellSelection]);

const isForwadble = useCallback(() => {
const peaces = playerPiles.find((e) => e.id === peaceNo);
return peaces && peaces.travel + diceNo <= 57;
},[peaceNo, diceNo, playerPiles]);

return(
<button onClick={onClick} disabled={(!(cell ? isCellEnabled && isForwadble() : isPileEnabled))}>
{
(cell ? isCellEnabled && isForwadble() : isPileEnabled) &&
<div className='activeCircle'></div>
}
<img src={backGgroundImage('pile'+player).image} alt='pile-img' />
</button>
)
}
 
export {Pile, PilePlot};
