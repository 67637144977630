import React, { useMemo } from 'react';
import Cell from './Cell';

const VerticalPath = ({color, cells}) => {

const groupPlot = useMemo(() => {
const groups = [];
for(let i = 0; i < cells.length; i+=3){
groups.push(cells.slice(i, i + 3));
}
return groups;
}, [cells]);

return (
<>
<div className='verPathLudo'>
<div className='verPathColLudo'>
{
groupPlot.map((group, groupIndex) => {
return (
<div className='verPathRowLudo' key={groupIndex}>
{
group.map((id, cellIndex) => {
return (
<div className='ludoCell' key={cellIndex}>
<Cell color={color} cellId={id} />
</div>
)
})
}
</div>
)
})
}
</div>
</div> 
</>
)
}

export default VerticalPath;
