const ludoReduce = (ludostate, action) => {
switch (action.type) {
case 'dice_run':
return {
...ludostate,
diceNo: action.payload,
isDiceRoll: true
}  

case 'enable_pile_player':
return {
...ludostate,
touchDiceBlock: true,
selectionPilePlayer: action.payload
}  

case 'enable_cell_player':
return {
...ludostate,
touchDiceBlock: true,
selectionCellPlayer: action.payload
}  

case 'disable_touch':
return {
...ludostate,
touchDiceBlock: true,
selectionPilePlayer: -1,
selectionCellPlayer: -1
}  

case 'unfreeze_dice':
return {
...ludostate,
touchDiceBlock: false,
isDiceRoll: false
}  

case 'winner_ludo':
return {
...ludostate,
winner: action.payload
}  

case 'chance_player':
return {
...ludostate,
chancePlayer: action.payload,
touchDiceBlock: false,
isDiceRoll: false
} 

case 'aanounce_winner':
return {
...ludostate,
winner: action.payload
} 

case 'update_pile':
const {playerNo, pileId, pos, travel } = action.payload;
const playerPile = ludostate[playerNo];
const currPile = playerPile.find((p) => p.id === pileId);
ludostate.selectionPilePlayer = -1;
ludostate.selectionCellPlayer = -1;

if(currPile){
currPile.pos = pos;
currPile.travel = travel;
const currPosIndex = ludostate.currentPosition.findIndex((p) => p.id === pileId);
if(pos === 0){
if(currPosIndex !== -1){
ludostate.currentPosition.splice(currPosIndex, 1);
}
}
else{
if(currPosIndex !== -1){
ludostate.currentPosition[currPosIndex] = {
id: pileId,
pos
}
}
else{
ludostate.currentPosition.push({id: pileId, pos}); 
}
}
}

return {
...ludostate,
}

default: return ludostate;
}
}

export default ludoReduce;