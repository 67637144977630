import React, { useState } from 'react';
import { backGgroundImage } from './assets/images';
import { GlobalLudo } from './assets/initialState';
//import {playLudoSound} from './assets/sound';

const Dice = ({color, player, disePos, data}) => {

const { 
updateDice,
updateChancePlayer,
enablePilePlayer,
enableCellPlayer,
ludostate
} = GlobalLudo();

const [diceRolling, setDiceRolling] = useState(false);
const diceNo = ludostate.diceNo;
const chancePlayer = ludostate.chancePlayer;
const playerPease = chancePlayer === 1 ? ludostate.player1 : 
chancePlayer === 2 ? ludostate.player2 : 
chancePlayer === 3 ? ludostate.player3 : ludostate.player4;
const isDiceRoll = ludostate.isDiceRoll;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const handleDicePress = async() => {
//let newDiceNo = Math.floor(Math.random() * 6) + 1;
let newDiceNo = 2;
//playLudoSound('dice_roll');
setDiceRolling(true);
await delay(800);
updateDice(newDiceNo);
setDiceRolling(false);

const isAnyPeaseAlive = data.findIndex(i => i.pos !== 0 && i.pos !== 57);
const isAnyPeaseLock = data.findIndex(i => i.pos === 0);

const checkPlayer1 = ludostate.player1.filter((e) => e.travel === 57);
const checkPlayer2 = ludostate.player2.filter((e) => e.travel === 57);
const checkPlayer3 = ludostate.player3.filter((e) => e.travel === 57);
const checkPlayer4 = ludostate.player4.filter((e) => e.travel === 57);

if(isAnyPeaseAlive === -1){
if(newDiceNo === 6){
enablePilePlayer(player);
}
else{
if(player === 1){
if(checkPlayer2.length === 4){
player = 2;  
}
if(checkPlayer2.length === 4 && checkPlayer3.length === 4){
player = 3;  
}
if(checkPlayer2.length === 4 && checkPlayer3.length === 4 && checkPlayer4.length === 4){
player = 4;  
}
}

if(player === 2){
if(checkPlayer3.length === 4){
player = 3;  
}
if(checkPlayer3.length === 4 && checkPlayer4.length === 4){
player = 4;  
}
if(checkPlayer3.length === 4 && checkPlayer4.length === 4 && checkPlayer1.length === 4){
player = 1;  
}
}
        
if(player === 3){
if(checkPlayer4.length === 4){
player = 4;  
}
if(checkPlayer4.length === 4 && checkPlayer1.length === 4){
player = 1;  
}
if(checkPlayer4.length === 4 && checkPlayer1.length === 4 && checkPlayer2.length === 4){
player = 2;  
}
}

if(player === 4){
if(checkPlayer1.length === 4){
player = 1;  
}
if(checkPlayer1.length === 4 && checkPlayer2.length === 4){
player = 2;  
}
if(checkPlayer1.length === 4 && checkPlayer2.length === 4 && checkPlayer3.length === 4){
player = 3;  
}
}

let chancePlayer = player + 1;
if(chancePlayer > 4){
chancePlayer = 1;
}

await delay(600);
updateChancePlayer(chancePlayer);
}
}else{

const canMove = playerPease.some((pile) => pile.travel + newDiceNo <= 57 && pile.pos !== 0);

if(
(!canMove && newDiceNo === 6 && isAnyPeaseLock === -1)
|| (!canMove && newDiceNo !== 6 && isAnyPeaseLock !== -1)
|| (!canMove && newDiceNo !== 6 && isAnyPeaseLock === -1)
){
if(player === 1){
if(checkPlayer2.length === 4){
player = 2;  
}
if(checkPlayer2.length === 4 && checkPlayer3.length === 4){
player = 3;  
}
if(checkPlayer2.length === 4 && checkPlayer3.length === 4 && checkPlayer4.length === 4){
player = 4;  
}
}

if(player === 2){
if(checkPlayer3.length === 4){
player = 3;  
}
if(checkPlayer3.length === 4 && checkPlayer4.length === 4){
player = 4;  
}
if(checkPlayer3.length === 4 && checkPlayer4.length === 4 && checkPlayer1.length === 4){
player = 1;  
}
}

if(player === 3){
if(checkPlayer4.length === 4){
player = 4;  
}
if(checkPlayer4.length === 4 && checkPlayer1.length === 4){
player = 1;  
}
if(checkPlayer4.length === 4 && checkPlayer1.length === 4 && checkPlayer2.length === 4){
player = 2;  
}
}

if(player === 4){
if(checkPlayer1.length === 4){
player = 1;  
}
if(checkPlayer1.length === 4 && checkPlayer2.length === 4){
player = 2;  
}
if(checkPlayer1.length === 4 && checkPlayer2.length === 4 && checkPlayer3.length === 4){
player = 3;  
}
}

let chancePlayer = player + 1;
if(chancePlayer > 4){
chancePlayer = 1;
}

await delay(600);
updateChancePlayer(chancePlayer);
return;
}

if(newDiceNo === 6){
enablePilePlayer(player);
}
enableCellPlayer(player);

}
}

return (
<>
{
disePos === 'left' ?
<div className='ludoDiceCol'>
<div className='ludoDicePile ludoDicePileLeft'>
<img src={backGgroundImage('pile'+player).image} alt='ludo_dice_pile' />
</div>
<div className='ludoDiceGradiant'>
<div className='ludoDice'>
{
(chancePlayer === player) &&
<button className='ludoDiceimgDiv' onClick={() => handleDicePress()} disabled={isDiceRoll}>
{
!diceRolling ?
<div className='activeLudoDice'>
<img src={backGgroundImage('dice'+diceNo).image} alt='ludo_dice' />
</div>
:
<div className='activerollLudoDice'>
<img src={backGgroundImage('dice7').image} alt='ludo_dice' />
</div>
}
</button>
}
</div>
</div>
<div className='indicateLeftArrow'>
{
(chancePlayer === player) && !diceRolling &&
<img src={backGgroundImage('arrowPoint').image} alt='indicate-arrow' />
}
</div>
</div>
:
<div className='ludoDiceCol'>
<div className='indicateRightArrow'>
{
(chancePlayer === player) && !diceRolling &&
<img src={backGgroundImage('arrowPoint').image} alt='indicate-arrow' />
}
</div>
<div className='ludoDiceGradiant'>
<div className='ludoDice'>
{
(chancePlayer === player) &&
<button className='ludoDiceimgDiv' onClick={() => handleDicePress()} disabled={isDiceRoll}>
{
!diceRolling ?
<div className='activeLudoDice'>
<img src={backGgroundImage('dice'+diceNo).image} alt='ludo_dice' />
</div>
:
<div className='activerollLudoDice'>
<img src={backGgroundImage('dice7').image} alt='ludo_dice' />
</div>
}
</button>
}
</div>
</div>
<div className='ludoDicePile ludoDicePileRight'>
<img src={backGgroundImage('pile'+player).image} alt='ludo_dice_pile' />
</div>
</div>
}
</>
)
}

export default Dice;
