export const backGgroundImage = (val) => {
switch (val) {
case 'ludo_bg':
return{
image: '/images/ludoKing/ludobackground.png'
}

case 'pile1':
return{
image: '/images/ludoKing/redCoin_sm.png'
}

case 'pile2':
return{
image: '/images/ludoKing/greenCoin_sm.png'
}

case 'pile3':
return{
image: '/images/ludoKing/yellowCoin_sm.png'
}

case 'pile4':
return{
image: '/images/ludoKing/blueCoin_sm.png'
}

case 'dice1':
return{
image: '/images/ludoKing/dice1_64.png'
}

case 'dice2':
return{
image: '/images/ludoKing/dice2_64.png'
}

case 'dice3':
return{
image: '/images/ludoKing/dice3_64.png'
}

case 'dice4':
return{
image: '/images/ludoKing/dice4_64.png'
}

case 'dice5':
return{
image: '/images/ludoKing/dice5_64.png'
}

case 'dice6':
return{
image: '/images/ludoKing/dice6_64.png'
}

case 'dice7':
return{
image: '/images/ludoKing/fullDice.png'
}

case 'arrowPoint':
return{
image: '/images/ludoKing/fast-forward.png'
}

case 'starIcon':
return{
image: '/images/ludoKing/star.png'
}

case 'turnIcon':
return{
image: '/images/ludoKing/turnArrow.png'
}

default:
return null;
}
}