import { createContext, useContext, useReducer } from "react";
import ludoReduce from "./ludoReduce";

const LudoContext = createContext();

const player1Initial = [
{id: 'A1', pos: 0, travel: 0},
{id: 'A2', pos: 0, travel: 0},
{id: 'A3', pos: 0, travel: 0},
{id: 'A4', pos: 0, travel: 0}
];

const player2Initial = [
{id: 'B1', pos: 0, travel: 0},
{id: 'B2', pos: 0, travel: 0},
{id: 'B3', pos: 0, travel: 0},
{id: 'B4', pos: 0, travel: 0}
];

const player3Initial = [
{id: 'C1', pos: 0, travel: 0},
{id: 'C2', pos: 0, travel: 0},
{id: 'C3', pos: 0, travel: 0},
{id: 'C4', pos: 0, travel: 0}
];

const player4Initial = [
{id: 'D1', pos: 0, travel: 0},
{id: 'D2', pos: 0, travel: 0},
{id: 'D3', pos: 0, travel: 0},
{id: 'D4', pos: 0, travel: 0}
];

const ludoinitialState = {
player1 : player1Initial,
player2 : player2Initial,
player3 : player3Initial,
player4 : player4Initial,
chancePlayer : 1,
diceNo : 1,
isDiceRoll : false,
selectionPilePlayer : -1,
selectionCellPlayer : -1,
touchDiceBlock : false,
currentPosition : [],
winner : null
}

const LudoProvider = ({children}) => {
const [ludostate, dispatch] = useReducer(ludoReduce, ludoinitialState);

const updateDice = (val) => {
dispatch({type : 'dice_run', payload: val}); 
}

const enablePilePlayer = (val) => {
dispatch({type : 'enable_pile_player', payload: val}); 
}

const enableCellPlayer = (val) => {
dispatch({type : 'enable_cell_player', payload: val}); 
}

const disableTouch = (val) => {
dispatch({type : 'disable_touch', payload: val}); 
}

const unfreezeDice = (val) => {
dispatch({type : 'unfreeze_dice', payload: val}); 
}

const updateWinner = (val) => {
dispatch({type : 'winner_ludo', payload: val}); 
}

const updateChancePlayer = (val) => {
dispatch({type : 'chance_player', payload: val}); 
}

const updatePile = (val) => {
dispatch({type : 'update_pile', payload: val}); 
}

const aanounceWinner = (val) => {
dispatch({type : 'aanounce_winner', payload: val}); 
}

return (
<LudoContext.Provider value={{ludostate, aanounceWinner, updateDice, updateChancePlayer, enablePilePlayer, enableCellPlayer, disableTouch, unfreezeDice, updateWinner, updatePile}}>{children}</LudoContext.Provider>
)
}

const GlobalLudo = () => {
return useContext(LudoContext);
}

export {LudoProvider, GlobalLudo};