import React from 'react';
import {Pile} from './Pile';
import { GlobalLudo } from './assets/initialState';
import { startPoint } from './assets/plot';

const Pocket = ({color, player, data}) => {

    const { updatePile, unfreezeDice } = GlobalLudo();

const handlePress = async val => {
let playerNo = val.id[0];
switch(playerNo){
case 'A':
playerNo = 'player1'
break;
case 'B':
playerNo = 'player2'
break;
case 'C':
playerNo = 'player3'
break;
case 'D':
playerNo = 'player4'
break;

default: return null;
}

updatePile({
    playerNo : playerNo,
    pileId : val.id,
    pos : startPoint[parseInt(playerNo.match(/\d+/)[0], 10) - 1],
    travel : 1
});

unfreezeDice();
}

return (
<>
<div className='ludoPocket' style={{backgroundColor: color}}>
<div className='ludoPocketMid'>
<div className='pileRow'>
<Pile 
color={color} 
player={player}
peaceNo={0} 
data={data}
onClick={handlePress}
/>
<Pile
color={color}
player={player}
peaceNo={1}
data={data}
onClick={handlePress}
/>
</div>
<div className='pileRow'>
<Pile 
color={color} 
player={player} 
peaceNo={2} 
data={data}
onClick={handlePress} 
/>
<Pile 
color={color} 
player={player} 
peaceNo={3} 
data={data}
onClick={handlePress} 
/>
</div>
</div>
</div>
</>
)
}

export default Pocket;
