import React, { useCallback, useMemo } from 'react';
import { backGgroundImage } from './assets/images';
import { colors } from './assets/Colors';
import { safeSpot, starSpot, arrowSpot, turningPoint, victoryStart, startPoint } from './assets/plot';
import { GlobalLudo } from './assets/initialState';
import {PilePlot} from './Pile';

const Cell = ({color, cellId}) => {

const { 
ludostate,
disableTouch, 
updatePile, 
unfreezeDice, 
updateChancePlayer, 
aanounceWinner 
} = GlobalLudo();

const isSafeSpot = useMemo(() => safeSpot.includes(cellId), [cellId]);
const isStarSpot = useMemo(() => starSpot.includes(cellId), [cellId]);
const isArrowSpot = useMemo(() => arrowSpot.includes(cellId), [cellId]);

const pilesAtPos = useMemo(() => 
ludostate.currentPosition.filter((e) => (e.pos) === (cellId)),
[ludostate, cellId]);

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const handleCellPress = useCallback(async(playerNo, peaceNo) => {

//console.log(playerNo +' -- '+ peaceNo +' -- '+ cellId);

const currentPosition = ludostate.currentPosition;
const diceNo = ludostate.diceNo;

const alpha = playerNo === 1 ? 'A' : playerNo === 2 ? 'B' : playerNo === 3 ? 'C' : 'D';

const pileAtPosition = currentPosition.filter((e) => e.pos === cellId);
const cellPiles = pileAtPosition[pileAtPosition.findIndex((o) => o.id[0] === alpha)];

disableTouch();

let finalPath = cellPiles.pos;
const beforePlayerPiles = ludostate[`player${playerNo}`].find((e) => e.id === peaceNo);
var beforeTravel = beforePlayerPiles.travel;

for(let i=0; i < diceNo; i++){
const playerPeaces = ludostate[`player${playerNo}`].find((e) => e.id === peaceNo);
let path = playerPeaces.pos + 1;

//console.log(playerPeaces);
//console.log(path);
//console.log(turningPoint);
//console.log(turningPoint[playerNo - 1]);

if(turningPoint.includes(path) && turningPoint[playerNo - 1] === path){
path = victoryStart[playerNo - 1];
}

//console.log(path);

if(path === 53){
path = 1;
}

finalPath = path;
beforeTravel += 1;   

updatePile({
playerNo : `player${playerNo}`,
pileId : playerPeaces.id,
pos : finalPath,
travel : beforeTravel
});

//sound play for pile move
await delay(200);
}

const updatedPosition = ludostate.currentPosition;
const finalPiles = updatedPosition.filter((e) => e.pos === finalPath);
const ids = finalPiles.map((item) => { return item.id[0]});
const uniqueIds = new Set(ids);
const areDiffrentId = uniqueIds.size > 1;

if(safeSpot.includes(finalPath) && starSpot.includes(finalPath)){
// play sound safe spot
}

if(areDiffrentId &&
!safeSpot.includes(finalPiles[0].pos) && 
!starSpot.includes(finalPiles[0].pos)){

const enemyPile = finalPiles.find((e) => e.id[0] !== peaceNo[0]);
const enemyId = enemyPile.id[0];
let pileNo = enemyId === 'A' ? 1 : enemyId === 'B' ? 2 : enemyId === 'C' ? 3 : 4;

let backPath = startPoint[pileNo - 1];
let i = enemyPile.pos;
//sound play collide

while(i !== backPath){
updatePile({
playerNo : `player${pileNo}`,
pileId : enemyPile.id,
pos : i,
travel : 0
});

await delay(0.6);
i--;
if(i === 0){
i = 52;
}
}

updatePile({
playerNo : `player${pileNo}`,
pileId : enemyPile.id,
pos : 0,
travel : 0
});

unfreezeDice();
return;
}

//console.log('cell player - '+ playerNo)
//console.log('cell player diceNo - '+ diceNo)
//console.log('cell player finalpath - '+ finalPath)
//console.log('cell player beforeTravel - '+ beforeTravel)

//const checkPlayer1 = ludostate.player1.filter((e) => e.travel === 57);
const checkPlayer2 = ludostate.player2.filter((e) => e.travel === 57);
//const checkPlayer3 = ludostate.player3.filter((e) => e.travel === 57);
//const checkPlayer4 = ludostate.player4.filter((e) => e.travel === 57);

if(diceNo === 6 || beforeTravel === 57){
updateChancePlayer(playerNo);
if(beforeTravel === 57){
//play sound home win
const playerAllPiles = ludostate[`player${playerNo}`];
if(winningCriteria(playerAllPiles)){
aanounceWinner(playerNo);
//play sound winning

if(playerNo === 1){
if(checkPlayer2 !== 4){

}
}

return;
}

// display image winning
unfreezeDice();
return;
}
}
else{
let chancePlayer = playerNo + 1;
if(chancePlayer > 4){
chancePlayer = 1;
}
updateChancePlayer(chancePlayer);
}

},[ludostate, cellId, disableTouch, updatePile, unfreezeDice, updateChancePlayer, aanounceWinner]);

function winningCriteria(playerAllPiles){
for(let pile of playerAllPiles){
if(pile.tarvel < 57){
return false;
}
}
return true;
}

return (
<>
<div className='ludoCellDiv' style={{backgroundColor : isSafeSpot ? color : 'white'}}>
{
isStarSpot && 
<img src={backGgroundImage('starIcon').image} alt='star-icon' />
}
{
isArrowSpot && 
<img src={backGgroundImage('turnIcon').image} alt='star-icon' style={
{
transform: cellId === 12 ? 'rotate(-90deg)' : cellId === 25 ? 'rotate(0)' :
cellId === 38 ? 'rotate(90deg)' : 'rotate(180deg)'
}
} />
}
{/*<span>{cellId}</span>*/}
{ 
pilesAtPos.map((data, index) => {

let currPileLength = pilesAtPos.length;

const playerNo = data.id[0] === 'A' ? 1 :
data.id[0] === 'B' ? 2 :
data.id[0] === 'C' ? 3 : 4;

const playerColor = playerNo === 1 ? colors.redColor :
playerNo === 2 ? colors.greenColor :
playerNo === 3 ? colors.yellowColor : colors.blueColor;

return(
<div className='cellPiles' key={data.id}
style={{transform: `scale(${currPileLength === 1 ? 1 : 0.7})
translateX(${currPileLength === 1 ? '0px' : (index % 2) ? '-6px' : '6px'})
translateY(${currPileLength === 1 ? '0px' : (index < 2) ? '-6px' : '6px'})`,
zIndex: ludostate.chancePlayer === playerNo ? 2 : 1
}}>
<PilePlot
cell={true}
player={playerNo}
peaceNo={data.id}
color={playerColor}
onClick={() => handleCellPress(playerNo, data.id)}
/>
</div> 
)
}) 
}

</div>
</>
)
}

export default Cell;
