import React from 'react';
import { colors } from './assets/Colors';
import Dice from './Dice';
import Pocket from './Pocket';
import VerticalPath from './VerticalPath';
import HorizPath from './HorizPath';
import LudoCenter from './LudoCenter';
import { plot1Data, plot2Data, plot3Data, plot4Data } from './assets/plot';
import { GlobalLudo } from './assets/initialState';

const LudoMain = () => {

const { ludostate } = GlobalLudo();

return (
<>
<div className='ludo_gameDiv'>
<div className='ludoGameCon'>
<div className='ludoGameWrap'>
<div className='diceContainerWrap'>
<div className='diceContainer'>
<Dice color={colors.greenColor} player={2} disePos={'left'} data={ludostate.player2} />
<Dice color={colors.yellowColor} player={3} disePos={'right'} data={ludostate.player3} />
</div>
</div>
<div className='LudoBoardContainer'>
<div className='ludoVerticalObj'>
<Pocket color={colors.greenColor} player={2} data={ludostate.player2} />
<VerticalPath color={colors.yellowColor} cells={plot3Data} />
<Pocket color={colors.yellowColor} player={3} data={ludostate.player3} />
</div>
<div className='ludoHorObj'>
<HorizPath color={colors.greenColor} cells={plot2Data} />
<LudoCenter
player1={ludostate.player1}
player2={ludostate.player2}
player3={ludostate.player3}
player4={ludostate.player4}
/>
<HorizPath color={colors.blueColor} cells={plot4Data} />
</div>
<div className='ludoVerticalObj'>
<Pocket color={colors.redColor} player={1} data={ludostate.player1} />
<VerticalPath color={colors.redColor} cells={plot1Data} />
<Pocket color={colors.blueColor} player={4} data={ludostate.player4} />
</div>
</div>
<div className='diceContainerWrap'>
<div className='diceContainer'>
<Dice color={colors.redColor} player={1} disePos={'left'} data={ludostate.player1} />
<Dice color={colors.blueColor} player={4} disePos={'right'} data={ludostate.player4} />
</div>
</div>
</div>
</div>
</div>
</>
)
}


export default LudoMain;
