import React from 'react';
import { PilePlot } from './Pile';

const LudoCenter = ({player1, player2, player3, player4}) => {

const player1Done = player1.filter((e) => e.travel === 57);
const player2Done = player2.filter((e) => e.travel === 57);
const player3Done = player3.filter((e) => e.travel === 57);
const player4Done = player4.filter((e) => e.travel === 57);

return (
<>
<div className='ludoCenter'>
<div className='ludoCenterDiv greenPolyLudo'>
<div className='ludoCenterDivPileWrap'>
{
player2Done.map((plyerDone, playerindex) => {
return (
<div className='ludoCenterDivPile' key={playerindex} 
style={{transform: `scale(${player2Done.length === 1 ? 1 : 0.7})
translateX(${player2Done.length === 1 ? '-6px' : playerindex % 2 === 0 ? '-12px' : '-3px'})
translateY(${player2Done.length === 1 ? '0' : playerindex < 2  ? '-3px' : '6px'})`
}}>
<PilePlot
cell={false}
peaceNo={plyerDone.id}
player={2}
/>
</div>
)
})
}
</div>
</div>
<div className='ludoCenterDiv yellowPolyLudo'>
<div className='ludoCenterDivPileWrap'>
{
player3Done.map((plyerDone, playerindex) => {
return (
<div className='ludoCenterDivPile' key={playerindex} 
style={{transform: `scale(${player3Done.length === 1 ? 1 : 0.7})
translateX(${player3Done.length === 1 ? '0' : playerindex % 2 === 0 ? '-6px' : '6px'})
translateY(${player3Done.length === 1 ? '-3px' : playerindex < 2  ? '-6px' : '3px'})`
}}>
<PilePlot
cell={false}
peaceNo={plyerDone.id}
player={3}
/>
</div>
)
})
}
</div>
</div>
<div className='ludoCenterDiv bluePolyLudo'>
<div className='ludoCenterDivPileWrap'>
{
player4Done.map((plyerDone, playerindex) => {
return (
<div className='ludoCenterDivPile' key={playerindex} 
style={{transform: `scale(${player4Done.length === 1 ? 1 : 0.7})
translateX(${player4Done.length === 1 ? '6px' : playerindex % 2 === 0 ? '2px' : '12px'})
translateY(${player4Done.length === 1 ? '-3px' : playerindex < 2  ? '-6px' : '3px'})`
}}>
<PilePlot
cell={false}
peaceNo={plyerDone.id}
player={4}
/>
</div>
)
})
}
</div>
</div>
<div className='ludoCenterDiv redPolyLudo'>
<div className='ludoCenterDivPileWrap'>
{
player1Done.map((plyerDone, playerindex) => {
return (
<div className='ludoCenterDivPile' key={playerindex} 
style={{transform: `scale(${player1Done.length === 1 ? 1 : 0.7})
translateX(${player1Done.length === 1 ? '0' : playerindex % 2 === 0 ? '-6px' : '6px'})
translateY(${player1Done.length === 1 ? '2px' : playerindex < 2  ? '0' : '8px'})`
}}>
<PilePlot
cell={false}
peaceNo={plyerDone.id}
player={1}
/>
</div>
)
})
}
</div>
</div>
</div>
</>
)
}

export default LudoCenter;
